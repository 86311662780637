import { Model } from "@/stores/models/Model";
import { GalleryImage } from "@/stores/models/GalleryImage";

export class Gallery extends Model {
  constructor(raw = {}) {
    super();
    this.id = raw.id || 0;
    this.images = (raw.images || [])
      .map((image) => new GalleryImage(image));
    this.createdAt = raw.created_at || "";
    this.updatedAt = raw.updated_at || "";
  }
}
