import { mainApi } from "@/stores/api/main-api";
import { Landing } from "@/stores/models/Landing";
import { Salon } from "@/stores/models/Salon";
import { Company } from "@/stores/models/Company";
import { Gallery } from "@/stores/models/Gallery";
import { useSalon } from "@/stores/salon";

export const useLanding = defineStore("landing", {
  state: () => ({
    _item: null,
    _salon: null,
    _company: null,
    _gallery: null,
    loading: {
      item: false,
    },
  }),

  getters: {
    landing() {
      return new Landing(this._item || {});
    },
    salon() {
      return new Salon(this._salon || {});
    },
    company() {
      return new Company(this._company || {});
    },
    gallery() {
      return new Gallery(this._gallery || {});
    },
  },

  actions: {
    setLoading(key, status) {
      this.loading[key] = status;
    },

    async loadLanding(id) {
      try {
        const salonModule = useSalon();
        this.setLoading("item", true);
        const response = await mainApi.getLanding(id);
        this._item = response.data;

        if (this.landing.salonId && this.salon.id !== this.landing.salonId) {
          const { data: salon } = await mainApi.getSalonById(this.landing.salonId);
          this._salon = salon;
        }
        if (this.landing.companyId && this.company.id !== this.landing.companyId) {
          const { data: company } = await mainApi.getCompanyById(this.landing.companyId);
          this._company = company[0];
        }
        if (this.landing.config.galleryId && this.gallery.id !== this.landing.config.galleryId) {
          const { data: gallery } = await mainApi.getGallery(this.landing.config.galleryId);
          this._gallery = gallery;
        }

        this.setLoading("item", false);
        return true;
      } catch(err) {
        this.setLoading("item", false);
        return false;
      }
    },
  },
});
