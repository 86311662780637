import merge from "lodash/merge";
import flatten from "lodash/flatten";
import { Model } from "@/stores/models/Model";
import { Salon } from "@/stores/models/Salon";
import { SalonService } from "@/stores/models/SalonService";
import i18n from "@/plugins/i18n.js";

class LandingListItem extends Model {
  constructor(raw = {}) {
    super();
    this.active = raw.active || false;
    this.text = raw.text || "";
    this.title = raw.title || "";
  }
}

export class LandingBlock extends Model {
  constructor(raw = {}) {
    super();
    this.type = raw.type || "";
    this.button = raw.button || "";
    this.classname = raw.classname || "";
    this.title = raw.title || "";
    this.description = raw.description || "";
    this.compare = raw.compare || false;
    if (raw.gallery) {
      this.gallery = this.compare
        ? raw.gallery
        : flatten(raw.gallery);
    }
    if (raw.list) {
      this.list = raw.list
        .map((listItem) => new LandingListItem(listItem));
    }
    this.limit = raw.limit || 0;
    this.noSwiper = raw.noSwiper || false;
    this.noInfo = raw.noInfo || false;
  }
}

export class Landing extends Model {
  constructor(raw = {}) {
    super();
    this.id = raw.id || "";
    this.company = raw.company || {};
    this.companyId = raw.company_id || 0;
    this.createdAt = raw.created_at || "";
    const data = raw.data || {};
    this.config = merge(
      { galleryId: null, showAddress: false, showPhone: false },
      data.config || {}
    );
    this.blocks = (data.blocks || [])
      .map((block) => new LandingBlock(block));
    this.name = raw.name || "";
    this.salon = new Salon(raw.salon || {});
    this.salonId = raw.salon_id || 0;
    this.salonService = new SalonService(raw.salon_service || {});
    this.salonServiceTitle = raw.salon_service?.data?.title || "";
    this.salonServiceCover = raw.salon_service?.data?.cover || "";
    this.salonServiceId = raw.salon_service_id || 0;
    this.slug = raw.slug || "";
    this.updatedAt = raw.updated_at || "";
  }
}
